import PaymentsTypes from '@/components/scripts/PageFooter/FooterNavigation/PaymentsTypes/index.vue'
import TrustpilotWidget from '@/components/scripts/TrustpilotWidget/index.vue'
import ConfirmDeleteModal from './ConfirmDeleteModal/index.vue'
import DeliveryInfoModal from './DeliveryInfoModal/index.vue'
import ReturnsInfoModal from './ReturnsInfoModal/index.vue'
import TermsInfoModal from './TermsInfoModal/index.vue'
import Express from './Express/index.vue'
import ReglazeMessage from './ReglazeMessage/index.vue'
import PromoMessage from './PromoMessage/index.vue'
import FreeDelivery from './FreeDelivery/index.vue'
import Item from './Item/index.vue'
import Prescription from './Prescription/index.vue'
import TwoForOne from './TwoForOne/index.vue'
import Accessories from './Item/Accessories/index.vue'
import ContactLenses from './Item/ContactLenses/index.vue'
import Googles from './Item/Googles/index.vue'
import Reglaze from './Item/Reglaze/index.vue'
import SavedItem from './Saved/Item/index.vue'
import SavedPrescription from './Saved/Prescription/index.vue'
import SavedAccessories from './Saved/Item/Accessories/index.vue'
import SavedContactLenses from './Saved/Item/ContactLenses/index.vue'
import SavedGoogles from './Saved/Item/Googles/index.vue'
import SavedReglaze from './Saved/Item/Reglaze/index.vue'
import { BigNumber } from 'bignumber.js'
import config from '@/../config'

import { mapState } from 'vuex'
import { reverseRouteName } from '@/../config/helper'
import { generatePromodo } from '@/utils/promodo'
import $ from 'jquery'

export default {
  name: 'Basket',
  components: {
    PaymentsTypes,
    TrustpilotWidget,
    ConfirmDeleteModal,
    DeliveryInfoModal,
    ReturnsInfoModal,
    TermsInfoModal,
    Item,
    Accessories,
    ContactLenses,
    Googles,
    Reglaze,
    Prescription,
    SavedItem,
    SavedPrescription,
    SavedAccessories,
    SavedContactLenses,
    SavedGoogles,
    SavedReglaze,
    Express,
    TwoForOne,
    ReglazeMessage,
    PromoMessage,
    FreeDelivery
  },
  data () {
    return {
      delOrderedItemID: null,
      delSavedItemID: null,
      hasDiscount: false,
      promoCode: undefined,
      hasExpress: false,
      clickedItem: {},
      itemMovedTo: '',
      showNoItemsSaved: false,
      showNoItemsBasket: true,
      order_id: false,
      logEntry: {},
      isOrderLoaded: false,
      isPageReady: false,
      freeShippingLimit: 75
    }
  },
  computed: {
    ...mapState({
      basket: (state) => ({...state.basket}),
      order: (state) => ({...(state.basket && state.basket.order || {})}),
      meta: (state) => ({
        ...state.formMeta['basket']
      }),
      basketLoading: (state) => state.basketLoading,
      loading: (state) => state.loading,
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      wishlist: (state) => state.wishlist,
      exchange: (state) => state.currency.exchange
    }),
    priceForFreeDelivery () {
      const total = new BigNumber(this.order.final_total)
      const shipping = new BigNumber(this.order.shipping_cost)
      const limit = new BigNumber(this.freeShippingLimit)
      return this.getPrice(this.calculatePrice(limit.minus(total).plus(shipping).toNumber(), false, false, false))
    },
    totalItems () {
      return this.order && this.order.items && this.order.items.reduce((acc, item) => {
        return !item.buy_later ? acc + item.quantity : acc
      }, 0) || false
    },
    items () {
      const {...data} = this.basket
      if (!data || !data.order || !data.order.orderItems) {
        return []
      }
      return data.order.orderItems.filter(item => !item.buy_later) || []
    },
    laterItems () {
      const {...data} = this.basket
      if (!data || !data.order || !data.order.orderItems) {
        return []
      }
      return data.order.orderItems.filter(item => item.buy_later) || []
    },
    reglazeItems () {
      return this.order && this.order.items && this.order.items.reduce((acc, item) => {
        return acc + ((!item.buy_later && item.type === 'reglaze') ? 1 : 0)
      }, 0) || false
    },
    hasProducts: function () {
      return this.totalItems > 0
    },
    hasSavedOrProduct () {
      return this.hasProducts || this.somethingSaved
    },
    basketOrderCount () {
      return this.order && this.order.orderItems ? this.order.orderItems.filter(item => !item.buy_later).length : 0
    },
    basketAmount: function () {
      return this.basketOrderCount
    },
    savedAmount: function () {
      return this.order && this.order.orderItems ? this.order.orderItems.filter(item => item.buy_later).length : 0
    },
    somethingSaved: function () {
      return this.savedAmount > 0
    }
  },
  methods: {
    convertPrice: function (price) {
      try {
        return parseFloat(this.exchange(this.vat(price).price)).toFixed(2)
      } catch (e) {
        return parseFloat(this.exchange(price)).toFixed(2)
      }
    },
    getPrice (price) {
      if (!this.currency) {
        return 0.0
      }
      const currency = Object.assign({}, this.currency)
      let val = new BigNumber(price)
      BigNumber.config(currency.to_greater && {ROUNDING_MODE: BigNumber.ROUND_CEIL} || {ROUNDING_MODE: BigNumber.ROUND_HALF_UP})
      val = val.shiftedBy(currency.precision_digit || 0)
      val = val.decimalPlaces(0)
      return val.shiftedBy(-currency.precision_digit || 0).toFixed(currency.precision_digit > 0 ? currency.precision_digit : 2)
    },
    formatNumber (num) {
      let val = new BigNumber(num)
      BigNumber.config({
        ROUNDING_MODE: BigNumber.ROUND_HALF_UP
      })
      val = val.shiftedBy(2)
      val = val.decimalPlaces(0)
      return val.shiftedBy(-2).toFixed(2)
    },
    getPriceWithVat (price) {
      const vat = new BigNumber(this.basket && this.basket.vat || 1)
      const m = new BigNumber(price)
      return this.getPrice(this.calculatePrice(m.dividedBy(vat).toNumber(), false, false, false))
    },
    getExchangePrice (price) {
      return this.getPrice(this.calculatePrice(price, false, false, false))
    },
    getTotal () {
      const amount = new BigNumber(this.getPriceWithVat(this.order.order_amount))
      const shipping = new BigNumber(this.getExchangePrice(this.order.shipping_cost))
      const discount = new BigNumber(this.getPriceWithVat(this.order.total_discount))
      const two4oneDiscount = new BigNumber(this.getPriceWithVat(this.order.two4one))
      return this.getPrice(amount.plus(shipping).minus(discount).minus(two4oneDiscount).toNumber())
    },
    assignItems (field, newItems) {
      newItems.map(item => {
        const index = this[field].findIndex(jtem => jtem.ordered_item_number == item.ordered_item_number)
        if (index >= 0) {
          Object.keys(item).map(key => {
            this[field][index][key] = item[key]
          })
          return
        }

        this[field].push(item)
      })

      this[field].map((item, index) => {
        const jndex = newItems.findIndex(jtem => jtem.ordered_item_number == item.ordered_item_number)
        if (jndex < 0) {
          this[field].splice(index, 1)
        }
      })
    },
    checkout: function (src) {
      /*
            location.reload()
            if (!this.basket.order || !this.basket.order.hash || this.basket.order.hash === undefined || this.basket.order.hash === '{hash}') {
              location.reload()
            }
      */
      src = src || false
      this.$router.push(this.$link('/checkout/' + this.basket.order.hash + (src || '')))
    },
    changeQtyItem (itemOrderedID, e) {
      let data = {
        'quantity': e.target.value,
        'ordered_item_number': itemOrderedID
      }
      this.$store.dispatch('setQtyItem', data)
    },
    removeItem (itemID) {
      this.$store.dispatch('removeItemFromBasket', itemID)
    },
    reverseRouteName: function (str, defaultName = 'route-name') {
      if (str && str.length > 0) {
        return reverseRouteName(str)
      }
      return defaultName
    },
    addToSave: function (itemID) {
      this.$store.dispatch('addToSave', itemID)
      this.showMovedBlock(itemID, 'later')
    },
    delFromSave: function (itemID) {
      this.$store.dispatch('delFromSave', itemID)
      this.showMovedBlock(itemID, 'basket')
    },
    submitDiscount (e) {
      e.preventDefault()
      this.$store.dispatch('onSubmitPromoCode', this.promoCode)
    },
    toggleDiscountBox (e) {
      e.preventDefault()
      this.hasDiscount = !this.hasDiscount
    },
    getItemPrice (item) {
      return this.getPriceWithVat(item.option.price * item.quantity)
    },
    getItemSave (item) {
      return +item.count_2_for_1 ? this.getPriceWithVat(item.selling_price - item.total) : 0.0
    },
    showMovedBlock (id, to) {
      this.clickedItem = {}
      if (id) {
        if (to === 'basket') {
          this.clickedItem = this.laterItems.filter(
            item => item.ordered_item_number === id
          )[0]
        } else if (to === 'later') {
          this.clickedItem = this.items.filter(
            item => item.ordered_item_number === id
          )[0]
        }
        this.itemMovedTo = to
        this.showNoItemsSaved = true
        this.showNoItemsBasket = false
      }
    },
    log (type) {
      if (!this.logEntry[type]) {
        let count = 0
        this.logEntry[type] = data => {
          if (config.env.NODE_ENV !== 'production') {
            console.debug(type + ' #' + ++count, JSON.parse(JSON.stringify(data)))
          }
        }
      }
      return this.logEntry[type]
    },
    removeOrderedItem (itemID) {
      this.delOrderedItemID = itemID
      this.delSavedItemID = null
      this.removeItem(itemID)
    },
    removeSavedItem (itemID) {
      this.delSavedItemID = itemID
      this.delOrderedItemID = null
      this.removeItem(itemID)
    },
    isReady () {
      return this.isOrderLoaded
    }
  },
  mounted () {
    $(window).on('beforeunload', function (e) {
      this.itemMovedTo = ''
      this.showNoItemsSaved = false
    })
    this.isPageReady = true

    this.$nextTick(() => generatePromodo(this.items, this.currency.currency_name, 1))
  },
  updated () {
    generatePromodo(this.items, this.currency.currency_name, 1)
  }
}
