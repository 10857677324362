import {BigNumber} from 'bignumber.js'
import { mapState } from 'vuex'

export default {
  name: 'FreeDelivery',
  props: {
    basket: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState({
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]})
    }),
    priceForFreeDelivery () {
      const total = new BigNumber(this.basket.order.final_total)
      const shipping = new BigNumber(this.basket.order.shipping_cost)
      const vat = new BigNumber(this.basket && this.basket.vat || 1)
      const limit = (new BigNumber(this.basket.order.freeInfo[0].price)).dividedBy(vat)
      return this.getPrice(limit.minus(this.getPriceWithVat(total.minus(shipping).toNumber())))
    },
    freeDeliveryPrice () {
      const vat = new BigNumber(this.basket && this.basket.vat || 1)
      const limit = (new BigNumber(this.basket.order.freeInfo[0].price)).dividedBy(vat)
      return this.getPrice(limit)
    }
  },
  methods: {
    getPrice (price) {
      return this.$parent.getPrice(price)
    },
    getPriceWithVat (price) {
      return this.$parent.getPriceWithVat(price)
    }
  }
}
